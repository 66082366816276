import { type FC } from 'react';
import { Notice } from './notice';
import { type DismissibleNoticeData, type NoticeData } from './types';

type Props = {
  notices: Array<DismissibleNoticeData | NoticeData>;
  onDismiss: (index: number) => void;
  className?: string
};

const NoticeStack: FC<Props> = ({ notices, onDismiss, className = '' }) => {
  function onNoticeDismiss(
    notice: DismissibleNoticeData | NoticeData,
    index: number
  ) {
    onDismiss(index);
    if ('onDismiss' in notice && notice.onDismiss) notice.onDismiss();
  }

  return (
    <ul className={`space-y-4 lg:space-y-6 ${className}`}>
      {notices.map((notice, index) => (
        <Notice
          key={index}
          {...notice}
          onDismiss={() => onNoticeDismiss(notice, index)}
        />
      ))}
    </ul>
  );
};

NoticeStack.displayName = 'NoticeStack';
export { NoticeStack };