import {type FC, Fragment, memo, type ReactNode, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";

type Props = {
  title?: ReactNode,
  children: ReactNode | ((closeCallback: () => void) => ReactNode),
  actions?: (closeCallback: () => void) => ReactNode[],
  trigger: (openCallback: () => void) => ReactNode,
  closableOverlay?: boolean, 
  initiallyOpen?: boolean,
  className?: string,
  overflowHidden?: boolean,
}

const Modal: FC<Props> = memo(function ({children, trigger, title, actions,closableOverlay = false, initiallyOpen = false, className, overflowHidden = false }) {
  const [isOpen, setIsOpen] = useState(initiallyOpen);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return <>
    {trigger(openModal)}

    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-10 ${className ?? ''}`}
        open={isOpen}
        onClose={() => closableOverlay ? closeModal() : null}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* Overlay */}
          <div className={`fixed inset-0 bg-black/25`} />
        </Transition.Child>

        <div className={`main-menu-offset fixed inset-0 overflow-y-auto`}>
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={`w-full max-w-md${overflowHidden ? 'overflow-hidden' : ''} rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}>
                {title && <Dialog.Title as="div" className="text-lg font-medium leading-6 text-gray-900">
                  {title}
                </Dialog.Title>}

                {typeof children === 'function' ? children(closeModal) : children}

                {actions && <ul className="mt-8 flex flex-row items-center justify-end space-x-8">
                  {actions(closeModal).map((Action, index) => <li key={index}>{Action}</li>)}
                </ul>}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
    </>
});

Modal.displayName = 'Modal';
export { Modal };
