import {type FC, type HTMLAttributes, memo, type ReactNode} from "react";
import {cva, type VariantProps} from "class-variance-authority";

const headingStyles = cva(
  'font-bold',
  {
    variants: {
      level: {
        1: 'text-4xl leading-12 lg:text-5xl lg:leading-15',
        2: 'text-3xl leading-10 lg:text-4xl lg:leading-11',
        3: 'text-xl leading-8 lg:text-2xl',
        4: 'text-lg leading-6 lg:text-xl'
      },
      textColor: {
        'black' : 'text-grey-700',
        'brand' : 'text-brand',
        'white' : 'text-white',
        'inherit': '',
      },
    },
    defaultVariants: {
      textColor: 'black',
    }
  }
);

export interface HeadingProps extends HTMLAttributes<HTMLOrSVGElement>, VariantProps<typeof headingStyles> {
  children: ReactNode,
  as?: keyof JSX.IntrinsicElements
}


const Heading : FC<HeadingProps> = memo(function ({as: Wrapper = "h1", textColor, children, className = '', ...props}) {
  return <Wrapper className={headingStyles({className, textColor, ...props})} {...props}>
    {children}
  </Wrapper>;
});

Heading.displayName = 'Heading';
export { Heading };