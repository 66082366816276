"use client";

import { convertNumberToDisplayablePriceString } from "@repo/utils";
import { type FC } from "react";
import { Heading } from "../heading";
import { GhostButton } from "../button/ghost-button";
import { Info } from "@phosphor-icons/react/dist/ssr/Info";
import { Modal } from "../modals/modal";


export type RenderLinkedProductNameParams = {
  name?: string;
  allTaxesIncludedPrice?: number;
  categories?: {
    name?: string;
    tooltip?: {
      title: string;
      content: string;
    };
  }[];
};

type Props = {
  data: RenderLinkedProductNameParams,
  showPrice?: boolean,
}


export const LinkedProduct: FC<Props> = function({data, showPrice = true}) {
  const category = data.categories?.[0];
  const priceDisplay = (
    <>
      {category?.name ?? data.name}{' '}
      {showPrice &&
        (data.allTaxesIncludedPrice !== undefined &&
        data.allTaxesIncludedPrice === 0
          ? 'offerts'
          : `${convertNumberToDisplayablePriceString(
              data.allTaxesIncludedPrice
            )} €`)}
    </>
  );

  if (!category?.tooltip) return priceDisplay;

  return (
    <Modal
      closableOverlay={true}
      className="z-40"
      trigger={(open) => (
        <button
          type="button"
          className={'flex flex-row items-center text-sm-r'}
          onClick={open}>
          {priceDisplay} <Info weight="bold" size={16} className="ml-1" />
        </button>
      )}
      title={
        <Heading as="h2" level={3} className="mb-6">
          {category.tooltip.title}
        </Heading>
      }
      actions={(close) => [
        <GhostButton key={1} onClick={close}>
          Fermer
        </GhostButton>,
      ]}>
      <div
        className="prose-white prose"
        dangerouslySetInnerHTML={{ __html: category.tooltip.content }}
      />
    </Modal>
  );
};