import { Disclosure } from "@headlessui/react";
import { type ResponseCartDTO } from "@repo/api-client";
import { CaretDown, CaretRight } from "@phosphor-icons/react/dist/ssr";
import { type FC } from "react"
import { formatCenterName } from '@repo/utils'
import { GhostButton } from "../button/ghost-button";

type Props = {
  carts: ResponseCartDTO[],
  activeCartId?: string,
  onCartSelected: (cart: ResponseCartDTO) => void,
};

export const CartList:FC<Props> = ({carts, onCartSelected, activeCartId}) => {
  return (<Disclosure>
      <Disclosure.Button className="flex flex-row items-center">
        {({open}) => (<>
          <div className="text-left">Vous avez actuellement <span className="font-bold text-brand">{carts.length - 1} autre(s) panier(s)</span> en cours.</div>
          <CaretDown
            weight={'bold'}
            className={`${open && 'rotate-180'} ml-2 transition-transform`}
          />
        </>)}
      </Disclosure.Button>
      <Disclosure.Panel>
        <ul className="space-y-2">
          {carts.filter((cart) => cart.id !== activeCartId).map((cart) => (
            <li key={cart.id}>
              <GhostButton
                onClick={() => onCartSelected(cart)}
                fullWidth
                className={`flex flex-row items-center justify-between`}
              >
                {formatCenterName(cart.center)}{' '}
                <CaretRight weight="bold" />
              </GhostButton>
            </li>
          ))}
        </ul>
      </Disclosure.Panel>
    </Disclosure>)
}