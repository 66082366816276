/* eslint-disable react-hooks/exhaustive-deps */
import { type ResponseCartDTO } from '@repo/api-client';
import { useContext, useEffect, useState } from 'react';
import type { DismissibleNoticeData, NoticeData } from '../notices/types';
import { CartContext } from './cart-controller.context';
import {
  type CartControllerLineParams,
  type CartErrorEvent,
  CartClientError,
} from '@/utils';
import toast from 'react-hot-toast';
import { Notice } from '@/components/notices/notice';

/**
  * @export
 */
export function useCart(cartId?: string) {
  const cartController = useContext(CartContext);

  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState<ResponseCartDTO | undefined>(undefined);
  const [items, setItems] = useState<ResponseCartDTO[]>([]);
  const [notices, setNotices] = useState<DismissibleNoticeData[]>([]);

  async function setActiveCart(cartId: string) {
    if (!cartController)
      return;

    cartController.useCartId(cartId);
  }

  function dismissNotice(noticeIndex: number): void {
    notices.splice(noticeIndex, 1);
    setNotices([...notices]);
  }

  function clearNotices() {
    setNotices([]);
  }

  /**
   * Triggered when the cart controller dispatch an CartUpdated event
   */
  function onCartUpdated(cart?: ResponseCartDTO) {
    setLoading(false);
    setCart(cart);
  }

  /**
   * Updates the UI to show loading indicator on load start events
   */
  function onLoadStart() {
    setLoading(true);
    clearNotices();
  }

  /**
   * Triggered when the cart controller emits an error event
   *
   * @param error
   */
  function onNotice(notice: NoticeData) {
    setLoading(false);

    setNotices((notices) => [...notices, notice]);
  }

  function onError(event: CartErrorEvent) {
    setLoading(false);

    switch (event.errorType) {
      case 'BAD_REQUEST':
        setCart(event.restoredCart);
        return;

      case 'NOT_FOUND':
        setCart(undefined);
        // cartController?.getCart().then(res => {
        //   setCart(res);
        //   if (res !== undefined)
        //     clearNotices();
        // });
        return;
      
      case "FATAL_ERROR" : 
        setCart(undefined);
        toast.custom(
          () => <Notice type="ERROR" message="Une erreur inattendue a entraîné la suppression de votre panier" />,
          { duration: 5_000 }
        )
        return;
    }
  }

  function handleLineQtyChange(data: CartControllerLineParams) {
    cartController?.updateLine(data);
  }

  function handleLineRemoved(lineId: string, centerId: string) {
    if (!cart?.centerId) return;

    cartController?.removeLine(lineId, centerId);
  }

  async function handleAddCoupon(couponCode: string, centerId: string): Promise<boolean> {
    // TODO error
    if (!cart?.id || !cartController) return false;

    try {
      await cartController.addCoupon(couponCode, centerId);
      return true;
    } catch (e) {
      return false;
    }
  }

  async function handleRemoveCoupon(couponCode: string, centerId: string): Promise<void> {
    // TODO error
    if (!cart?.id || !cartController) return;

    await cartController.removeCoupon(couponCode, centerId);
  }

  useEffect(() => {
    // Allow to use specific cart id controller
    if (cartId) cartController?.useCartId(cartId);

    // eslint-disable-next-line no-console
    if (!cartController) console.warn('useCart() : cartController is missing, please ensure that <CartProvider> is mounted on the tree');

    // Register events
    cartController?.emitter.on('cartUpdated', onCartUpdated);
    cartController?.emitter.on('loading', onLoadStart);
    cartController?.emitter.on('notice', onNotice);
    cartController?.emitter.on('error', onError);

    // Loads the cart from local storage and API
    setLoading(true);

    cartController?.getCart().then((c) => {
      setLoading(false);
      if ((c instanceof CartClientError)) return;
      setCart(c);

      if (c?.messages && c.messages.length > 0) {
        setNotices((n) => [...n, ...c.messages]);
      }
    // eslint-disable-next-line no-console
    }).catch(e => console.error(e));

    cartController?.listCarts()
      .then(items => setItems(items ?? []));

    return () => {
      // Remove event listeners
      cartController?.emitter.off('cartUpdated', onCartUpdated);
      cartController?.emitter.off('loading', onLoadStart);
      cartController?.emitter.off('notice', onNotice);
      cartController?.emitter.off('error', onError);

      // Reset cart controller back to multi cart provider
      cartController?.useCartId();
    };
  }, [cartController, cartId]);

  return {
    isLoading: loading,
    cart,
    setCart,
    notices,
    dismissNotice,
    handleLineQtyChange,
    handleLineRemoved,
    clearNotices,
    handleAddCoupon,
    handleRemoveCoupon,
    cartList: items,
    setActiveCart,
  };
}
