import { type ResponseCartCouponDTO } from '@repo/api-client';
import { X } from '@phosphor-icons/react/dist/ssr';
import { type FC, memo } from 'react';

type Props = {
  coupon: ResponseCartCouponDTO;
  onRemove: () => void;
};

const CouponItem: FC<Props> = memo(function ({ coupon, onRemove }) {
  return (
    <button
      type="button"
      className="flex cursor-pointer flex-row items-center rounded-full bg-light-grey px-4 py-1 text-sm font-semibold uppercase transition-colors hover:bg-gray-200"
      onClick={onRemove}
    >
      {coupon.code}

      <X weight="bold" size={16} className="-mr-2 ml-2" />
    </button>
  );
});

CouponItem.displayName = 'CouponItem';
export { CouponItem };
