import { type FC, type ReactNode } from 'react';
import { type FieldInputProps } from './field-input';
import { InputLabel } from './input-label';

type Props = FieldInputProps & {
  leading?: ReactNode;
  trailing?: ReactNode;
};

export const GroupedInput: FC<Props> = function (props) {
  const id = props.id ?? props.name;
  return (
    <div>
      {props.label && (
        <InputLabel
          for={id}
          label={props.label}
          required={props.required}
          tooltip={props.tooltip}
        />
      )}
      <div className="mt-1 flex w-full flex-row items-center justify-between rounded-lg border-2 border-gray-900">
        {props.leading}

        <input
          id={id}
          name={props.name}
          type={props.type ?? 'text'}
          placeholder={props.placeholder ?? (props.label ? `${props.label}...` : '')}
          value={props.value}
          onChange={(e) => {
            if (props.onChange) props.onChange(e.target.value);
          }}
          required={props.required}
          {...props.attrs}
          className={`${props.attrs?.className} flex-1 appearance-none overflow-hidden border-none bg-transparent px-4 py-2 outline-none placeholder:normal-case`}
        />

        {props.trailing}
      </div>
    </div>
  );
};
