import Link from 'next/link';
import { formatCenterUrl, formatCenterName } from '@repo/utils';
import type { FC, ReactNode } from 'react';
import { NoticeStack } from '../notices/notice-stack';
import { CartList } from './cart-list';
import { CartTotal } from './cart-total';
import { CouponForm } from './coupon-form';
import { CouponItem } from './coupon-item';
import { ProductLine } from './product-line';
import { useCart } from './use-cart.hook';
import { LoadingOverlay } from '../loading/loading-overlay';
import { Heading } from '../heading';
import { FilledButton } from '../button/filled-button';
import type { ResponseCartProductLineDTO } from '@repo/api-client';

type Props = {
  detailedTotals?: boolean;
  controls?: {
    cartSelector?: boolean;
    checkoutButton?: boolean;
  };
  minimal?: boolean;
  onCartEmpty?: ReactNode
} & ({ cartId?: string; } | { useCartData: ReturnType<typeof useCart> });

export const Cart: FC<Props> = (props) => {
  const {
    isLoading,
    cart,
    dismissNotice,
    notices,
    handleLineQtyChange,
    handleLineRemoved,
    handleAddCoupon,
    handleRemoveCoupon,
    cartList,
    setActiveCart,
  // TODO
  // eslint-disable-next-line react-hooks/rules-of-hooks
  } = "useCartData" in props ? props.useCartData : useCart(props.cartId);

  const handleProductRemove = (line: ResponseCartProductLineDTO) => {
    if (!cart)
      return;
    
    handleLineRemoved(line.id!, cart.centerId);
  }

  // If no cart yet
  if (!cart || cart.productLines.length < 1) {
    // If it's loading, show loading indicator
    if (isLoading)
      return (
        <LoadingOverlay show />
      );

    // If no cart provided
    return (
      <div className="py-16 text-center">
        {props.onCartEmpty ?? <p className="text-center text-gray-500">
          Vous n&apos;avez aucun box
          <br /> dans votre panier
        </p>}
      </div>
    );
  }


  return (
    <>
      {/* Introduction */}
      <div className="mb-6 mt-4 text-sm text-gray-500">
        <p>
          Votre panier pour le centre{' '}
          <Link
            href={formatCenterUrl(cart.center.path!)}
            className="font-bold text-brand"
            target={"_blank"}
          >
            {formatCenterName(cart.center)}
          </Link>
          .
        </p>

        {cartList.length > 1 && (props.controls?.cartSelector ?? true) && (
          <CartList
            activeCartId={cart.id}
            carts={cartList}
            onCartSelected={(selectedCart) => setActiveCart(selectedCart.id)}
          />
        )}
      </div>

      {notices.length > 0 && (
        <NoticeStack notices={notices} onDismiss={dismissNotice} />
      )}

      <Heading as={'div'} level={3} className="mb-4 mt-6">
        Boxes
      </Heading>

      <div className={'relative -mx-6 overflow-hidden px-6'}>
        {/* Loading indicator */}
        <LoadingOverlay show={isLoading} />

        {/* Product items */}
        <ul className="space-y-4">
          {cart.productLines.map((line) => (
            <li key={line.id!}>
              <ProductLine
                line={line}
                onQtyChange={(qty) =>
                  handleLineQtyChange({
                    productId: line.productId,
                    centerId: cart.centerId,
                    qty,
                  })
                }
                onRemove={() => handleProductRemove(line)}
              />
            </li>
          ))}
        </ul>

        {/* Coupons */}
        <div className="mt-8">
          { (props.detailedTotals && cart.coupons && cart.coupons.length > 0) && (
            <>
              <Heading as={'div'} level={4} className="mb-6">
                Code promo appliqué
              </Heading>

              <ul className="mb-2 flex flex-row flex-wrap gap-2">
                {cart.coupons.map((coupon) => (
                  <li key={coupon.code}>
                    <CouponItem
                      coupon={coupon}
                      onRemove={() =>
                        handleRemoveCoupon(coupon.code, cart.centerId)
                      }
                    />
                  </li>
                ))}
              </ul>
            </>
          )}

          {/* Coupon codes form */}
          <CouponForm
            onSubmit={(couponCode: string) =>
              handleAddCoupon(couponCode, cart.centerId)
            }
          />
        </div>

        {/* Total */}
        <CartTotal
          totals={cart.totals!}
          detailed={props.detailedTotals}
          className="mb-6 mt-8"
        />

        {props.controls?.checkoutButton && (
          <Link href={`/commande?cartId=${cart.id}`}>
            <FilledButton fullWidth>
              Passer commande
            </FilledButton>
          </Link>
        )}
      </div>
    </>
  );
};
