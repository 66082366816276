import { Transition } from '@headlessui/react';
import { type FC } from 'react';
import { LoadingIndicator } from './loading-indicator';

type Props = {
  show: boolean
}

export const LoadingOverlay: FC<Props> = function ({show}) {
  return (
    <Transition
      show={show}
      as="div"
      className="absolute inset-0 z-10 flex items-center justify-center bg-white/75"
      enter="transition-opacity duration-75 z-50"
      enterFrom="opacity-0"
      enterTo="opacity-75"
      leave="transition-opacity duration-75"
      leaveFrom="opacity-75"
      leaveTo="opacity-0 hidden"
    >
      <LoadingIndicator className="text-gray-500" />
    </Transition>
  );
};
