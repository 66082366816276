import { convertNumberToDisplayablePriceString } from '@repo/utils';
import { useMemo } from 'react';

export function convertPrice(price: string | number | undefined): string | undefined {
  if (price === undefined) return undefined;
  if (typeof price === 'string') return price;

  return convertNumberToDisplayablePriceString(price);
}

export type UseRenderedPriceArgs = {
  price?: string | number;
  allTaxesIncludedPrice?: string | number,
  regularPrice?: string | number
};

export const useRenderedPrice = function (args: UseRenderedPriceArgs) {
  return useMemo(() => {
    const price = convertPrice(args.allTaxesIncludedPrice ?? args.price);
    const regularPrice = convertPrice(args.regularPrice);

    return {
      price,
      regularPrice,
    };
  }, [args.allTaxesIncludedPrice, args.price, args.regularPrice]);
};
