import { type ResponseCartDTOTotals as CartEntityTotals, type TotalsDataProductsTotal as TotalsDataTotal } from '@repo/api-client';
import { convertNumberToDisplayablePriceString } from '@repo/utils';
import { type FC } from 'react';

type Props = {
  totals: CartEntityTotals;
  detailed?: boolean;
  className?: string;
};

export const CartTotalLine: FC<{
  total: TotalsDataTotal;
  label: string;
  className?: string;
}> = function ({ total, label, className }) {
  return (
    <div className={`${className} flex flex-row items-center justify-between gap-4`}>
      <div className="flex-1">{label}</div>

      <div className="text-right">
        {convertNumberToDisplayablePriceString(total.allTaxesIncluded!)} € <span className="text-xs">TTC</span>
      </div>
    </div>
  );
};

export const CartTotal: FC<Props> = function ({ totals, className, detailed }) {
  const showDetailed = detailed && totals.discountsTotal?.withoutTaxes !== 0;

  return (
    <div
      className={`${className} space-y-2 rounded-lg bg-brand-black p-4 text-white`}
    >
      {showDetailed && (
        <>
          {totals.productsTotal && (
              <CartTotalLine
                total={totals.productsTotal}
                label={'Total avant réduction'}
                className="text-sm"
              />
            )}
          {totals.discountsTotal && (
            <CartTotalLine
              total={totals.discountsTotal}
              label={'Réduction'}
              className="text-sm"
            />
          )}
        </>
      )}

      <CartTotalLine
        total={totals.total!}
        label={'Total'}
        className={`font-semibold ${showDetailed && '!mt-4'}`}
      />
    </div>
  );
};
