import { Popover } from '@headlessui/react';
import { Info } from '@phosphor-icons/react/dist/ssr';
import { type FC, memo, type ReactNode } from 'react';

export type InputLabelProps = {
  for?: string;
  label: ReactNode | string;
  required?: boolean;
  className?: string;
  tooltip?: ReactNode | string;
};

const InputLabel: FC<InputLabelProps> = memo((props) => {
  const Wrapper = props.for !== undefined ? 'label' : 'span';

  const output = (
    <Wrapper
      htmlFor={props.for}
      className={`flex cursor-pointer flex-row items-center text-xs font-semibold ${props.className}`}
    >
      <span>{props.label} {props.required && <span className="text-red-400">*</span>}</span> {props.tooltip && <Info weight="bold" className="ml-1" />}
    </Wrapper>
  );

  if (!props.tooltip) return output;

  return (
    <Popover className={"relative"}>
      <Popover.Button as="div">{output}</Popover.Button>
      <Popover.Panel className="absolute top-full z-10 mt-4">
        <div className="rounded-lg bg-brand-black p-4 text-sm text-white shadow-lg">
          {props.tooltip}
        </div>
      </Popover.Panel>
    </Popover>
  );
});

InputLabel.displayName = 'InputLabel';
export { InputLabel };